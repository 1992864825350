* {
  box-sizing: border-box;
}

html {
  --bg: #efefef;
  --fg: #1e1e1e;
  --gray: #555;
  --light-gray: #ccc;
  --shadow: #e6e6e6;
  --success: #5ace84;
  --primary: #ff585d;
  --secondary: #0072ce;
  background-color: var(--bg);
  color: var(--fg);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-size: calc(.9em + .5vw);
  line-height: 1.3;
}

body {
  margin: 0;
  padding: 0;
}

main {
  margin: 0 auto;
}

h1 {
  text-align: center;
  background-image: url("logo-black.54439fde.svg");
  background-position: 50% 1em;
  background-repeat: no-repeat;
  background-size: auto 1.5em;
  margin-top: 0;
  padding: 3.5em 0 0;
  font-size: 1.5em;
}

.greeting {
  color: var(--secondary);
  text-decoration: underline;
}

h2 {
  text-align: center;
}

.please-wait .change {
  pointer-events: none;
}

ul.information {
  text-align: left;
  margin: 2em 0;
  padding: 0;
  font-size: .8em;
}

.information li:first-child {
  border-top: 1px solid var(--light-gray);
}

.information li {
  border-bottom: 1px solid var(--light-gray);
  padding: .5em 0;
  list-style: none;
}

.change button {
  margin-top: 30px;
}

a, .link {
  color: var(--primary);
  text-decoration: none;
}

a:hover, a:focus, .link:hover, .link:focus {
  text-decoration: underline;
}

a:active, .link:active {
  color: var(--secondary);
}

button, input {
  font: inherit;
  outline: none;
}

main.please-wait .change button {
  pointer-events: none;
  background-color: #fff;
  position: relative;
}

main.please-wait .change button span {
  visibility: hidden;
}

button {
  background-color: var(--secondary);
  color: #efefef;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: .3em .75em;
  transition: transform 30ms;
}

button:hover, button:focus {
  box-shadow: inset 0 0 10em #fff3;
}

input {
  background-color: var(--light-gray);
  caret-color: var(--primary);
  color: inherit;
  border: none;
  border-radius: 5px 0 0 5px;
  padding: .25em .5em;
}

input::selection {
  background-color: var(--secondary);
  color: #efefef;
}

input:focus {
  box-shadow: inset 0 0 10em #00000005;
}

code {
  color: var(--gray);
}

li {
  padding-bottom: 1em;
}

@media (prefers-color-scheme: dark) {
  html {
    --bg: #1e1e1e;
    --fg: #efefef;
    --gray: #aaa;
    --shadow: #2a2a2a;
    --light-gray: #444;
  }

  h1 {
    background-image: url("logo-white.605d2742.svg");
  }

  input:focus {
    box-shadow: inset 0 0 10em #ffffff05;
  }
}

main.please-wait .loader, main.please-wait .loader:after {
  display: inline-block;
}

.loader, .loader:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: none;
}

.loader {
  text-indent: -9999em;
  border-top: 3px solid var(--secondary);
  border-right: 3px solid var(--secondary);
  border-bottom: 3px solid var(--secondary);
  border-left: 3px solid #fff;
  font-size: 10px;
  animation: load8 1.1s linear infinite;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  transform: translateZ(0);
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

pre {
  background-color: #e7eeef;
}

.sign-out {
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.result {
  margin-inline: 0;
  padding: 10px;
  font-size: 1.17em;
  font-weight: bold;
  display: block;
}

.failed {
  color: #fff;
  background-color: #8b0000;
}

.success {
  color: #fff;
  background-color: #006400;
}

html, body, .container, main, #root {
  height: 100%;
}

@media only screen and (min-width: 630px) {
  .menu-container {
    max-width: 26em;
    margin: 0 auto;
  }

  .container {
    height: 100%;
  }

  .challenge-info {
    float: left;
    width: 50%;
    height: 100%;
    padding: 0 1em;
    overflow-y: scroll;
  }

  .challenge-submit {
    float: left;
    width: 50%;
    height: 100%;
  }

  .challenge-submit-form {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 629px) {
  .container {
    display: block;
  }

  .challenge-info, .challenge-submit {
    float: none;
    width: 100%;
  }
}

.light {
  animation: anim 2s infinite;
}

.links li {
  padding-bottom: .2em;
}

.links {
  margin-top: 0;
}

@keyframes anim {
  0% {
    x: 111.416;
    y: 50.37;
  }

  100% {
    x: 400;
    y: 50;
  }
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  background: none !important;
}

code[class*="language-"], pre[class*="language-"] {
  color: #000;
  text-shadow: 0 1px #fff;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection, code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #905;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #690;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
  background: #ffffff80;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.function, .token.class-name {
  color: #dd4a68;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

/*# sourceMappingURL=index.d2155cdf.css.map */
